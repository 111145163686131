import axios from 'axios';
import store from '@/store'

const httpClient = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {}
});

httpClient.interceptors.request.use((config) => {
      store.commit('mutationer', { isLoading: true })
      return config;
}, (error) => {
      return Promise.reject(error);
});

httpClient.interceptors.response.use(
      (response) => {
            setTimeout(() => {
                  store.commit('mutationer', { isLoading: false })
            }, 500);
            return response
      },
      (error) => {
            store.commit('mutationer', { isLoading: false })
            Promise.reject(error)
      }
);

export default httpClient;